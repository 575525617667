<template>
  <div id="pageCadastroNotaEntrada">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg10 sm12 xs12>
          <v-card>
            <v-toolbar color="pink" dark flat dense cad>
              <v-toolbar-title class="subheading">Nova entrada</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="">
              <entrada-form></entrada-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import EntradaForm from "@/components/notaEntrada/formNotaEntrada.vue"
export default {
  components: {
    EntradaForm
  },
  data: () => ({}),
  created() {}
}
</script>
