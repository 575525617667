<template>
  <v-dialog v-model="dialog" persistent max-width="100" max-length="80">
    <v-card>
      <v-card-text>
        <v-progress-circular :size="70" :width="7" :color="'pink accent-1'" indeterminate></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { serverBus } from "../../main"
export default {
  data() {
    return {
      dialog: false
    }
  },
  created() {
    serverBus.$on("fecharAbrirDialog", value => {
      this.dialog = value
    })
  }
}
</script>
