<template>
  <v-select
    autocomplete
    label="Fornecedor"
    placeholder="Selecione..."
    :rules="[() => !!codFornecedor || 'Campo obrigatório']"
    v-model="codFornecedor"
    :items="fornecedores"
    item-value="id"
    @input="setSelected"
    item-text="nome"
    required
  ></v-select>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoFornecedor } from "../../servicos/servicoFornecedor"
const servicoFornecedor = new ServicoFornecedor()
export default {
  data: () => ({
    fornecedores: [],
    codFornecedor: ""
  }),
  created() {
    servicoFornecedor.buscarFornecedor().then(
      res => {
        if (res.status === 200) {
          this.fornecedores = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },
  methods: {
    setSelected(value) {
      serverBus.$emit("fornecedorSelected", value)
    }
  }
}
</script>
