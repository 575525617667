<template>
  <v-layout row wrap justify-center>
    <v-flex lg12 sm12 xs12>
      <div class="d-flex">
        <v-select
          autocomplete
          placeholder="Selecione..."
          return-object
          v-model="value"
          :rules="[() => !!value || 'Obrigatório selecionar um ou mais produto']"
          :items="produtos"
          item-text="informacoesExibir"
          attach
          chips
          label="Produtos"
          multiple
          @input="setSelected"
          append-outer-icon="library_add"
          :append-outer-icon-cb="adicionarProdutos"
        >
        </v-select>
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
import { serverBus } from "../../../main"
import { ServicoProduto } from "../../../servicos/servicoProduto"
const servicoProduto = new ServicoProduto()
export default {
  data: () => ({
    produtos: [],
    value: []
  }),
  created() {
    servicoProduto.buscarProdutosSelect().then(
      res => {
        if (res.status === 200) {
          this.produtos = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )

    serverBus.$on("produtoSelected", value => {
      this.selectedFruits = value
      this.value = value
    })
    serverBus.$on("buscarProdutoPorRomaneio", value => {
      servicoProduto.buscarProdutosRomaneioSelect(value).then(
        res => {
          if (res.status === 200) {
            this.produtos = res.data
          }
        },
        // eslint-disable-next-line
        err => console.log(err)
      )
    })
  },
  computed: {
    likesAllFruit() {
      return this.selectedFruits.length === this.fruits.length
    },
    likesSomeFruit() {
      return this.selectedFruits.length > 0 && !this.likesAllFruit
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box"
      if (this.likesSomeFruit) return "mdi-minus-box"
      return "mdi-checkbox-blank-outline"
    }
  },
  methods: {
    adicionarProdutos() {
      serverBus.$emit("produtosSelecionados", this.selectedFruits)
    },
    setSelected(value) {
      this.selectedFruits = value
    }
  }
}
</script>
