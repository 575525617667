import axios from "axios"

export class ServicoFornecedor {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Fornecedor`
  }
  buscarFornecedor() {
    return axios({
      url: this.urlServico + `/GetAll`,
      method: "GET"
    })
  }
  salvarFornecedor(fornecedor) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    if (fornecedor.id == 0) {
      return axios.post(this.urlServico + "/Salvar", fornecedor, config).catch(error => {
        alert("Erro ao salvar o fornecedor " + error)
        return Promise.reject(error)
      })
    } else {
      return axios.post(this.urlServico + "/Alterar", fornecedor, config).catch(error => {
        alert("Erro ao salvar o fornecedor " + error)
        return Promise.reject(error)
      })
    }
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `/GetById?id=${id}`,
      method: "GET"
    })
  }
}
