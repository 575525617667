<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <select-fornecedor class="mr-2"></select-fornecedor>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-menu
            class="pr-2"
            ref="dataCompra"
            lazy
            :close-on-content-click="false"
            v-model="dataCompra"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataCompraMercadoria"
          >
            <v-text-field
              slot="activator"
              label="Data compra"
              v-model="dataCompraMercadoria"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataCompraMercadoria" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dataCompra = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dataCompra.save(dataCompraMercadoria)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            class="pr-2"
            ref="dataEntrada"
            lazy
            :close-on-content-click="false"
            v-model="dataEntrada"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataEntradaEmEstoque"
          >
            <v-text-field
              slot="activator"
              label="Data entrada"
              v-model="dataEntradaEmEstoque"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataEntradaEmEstoque" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dataEntrada = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dataEntrada.save(dataEntradaEmEstoque)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <select-produto class="mr-2"></select-produto>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headers" :search="search" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.id }}</td>
              <td style="display:none;">{{ props.item.idProduto }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>
                <v-text-field
                  style="width:80px"
                  type="number"
                  name="quantidade"
                  v-model="props.item.quantidade"
                  required
                  :readonly="props.item.id != 0"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  name="preco"
                  style="width:80px"
                  placeholder="Preço de compra"
                  v-model="props.item.precoCompra"
                  v-money="money"
                  required
                  :readonly="props.item.id != 0"
                ></v-text-field>
              </td>
              <td style="width:350px">
                <div class="d-flex">
                  <v-divider class="mx-3" inset vertical></v-divider>
                  <v-text-field
                    style="width:150px"
                    label="Nº sacola"
                    name="sacola"
                    v-model="props.item.idSacola"
                    required
                    :readonly="props.item.id != 0"
                  ></v-text-field>
                  <v-btn
                    title="Visualizar códigos vinculados à sacola"
                    icon
                    color="primary"
                    @click.stop="visualizarSacola(props.item)"
                    v-if="props.item.id == 0"
                  >
                    <v-icon>notes</v-icon>
                  </v-btn>
                  <v-divider class="mx-3" inset vertical></v-divider>
                  <v-btn icon color="primary" @click.stop="abrirModal(props.item)" v-if="props.item.id == 0">
                    <v-icon>post_add</v-icon>
                  </v-btn>
                </div>
              </td>
            </template>
          </v-data-table>
        </div>
        <v-dialog v-model="dialog" max-width="800">
          <v-card v-if="produtoModal">
            <v-card-title class="headline">Código de barras</v-card-title>
            <v-card-text>
              <v-textarea
                solo
                name="codigoBarras"
                label="Código de barras"
                rows="15"
                :readonly="view"
                v-model="produtoModal.CodigoBarras"
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark text @click="dialog = false">Cancelar</v-btn>
              <v-btn color="primary" dark text @click="validarCodigoBarra(produtoModal)">Salvar</v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="codigosNaSacola">
            <v-card-title class="headline">Códigos de barras</v-card-title>
            <v-card-text>
              <v-data-table :headers="headersSacola" :items="codigosNaSacola" class="elevation-0">
                <template v-slot:items="props">
                  <td>{{ props.item }}</td>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark text @click="dialog = false">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="form-btn">
          <v-btn outline @click="submit" color="primary">Salvar</v-btn>
          <router-link :to="{ name: 'entrada' }" class="nounderline">
            <v-btn outline>Voltar</v-btn>
          </router-link>
        </div>
      </v-flex>
    </v-layout>
    <v-snackbar :color="'error'" v-model="snackbar" :top="true" :timeout="timeout">
      {{ text }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <div>
      <progress-circular></progress-circular>
    </div>
  </v-form>
</template>
<script>
import { VMoney } from "v-money"
import { serverBus } from "../../main"
import { ServicoCodigoBarras } from "../../servicos/servicoCodigoBarras"
import { ServicoNotaEntrada } from "../../servicos/servicoNotaEntrada"
import ProgressCircular from "../progressCircular/progressCircular"
import Entrada from "../../domain/entrada/Entrada"
import ProdutosEntrada from "../../domain/entrada/ProdutosEntrada"
import SelectProduto from "../produto/select/selectProdutos.vue"
import SelectFornecedor from "../fornecedor/selectFornecedor.vue"
import ServicoSacola from "../../servicos/servicoSacola"
const servicoSacola = new ServicoSacola()
const servicoCodigoBarras = new ServicoCodigoBarras()
const servicoNotaEntrada = new ServicoNotaEntrada()
export default {
  components: {
    SelectProduto,
    SelectFornecedor,
    ProgressCircular
  },
  data() {
    return {
      dialog: false,
      id: null,
      valid: false,
      idFornecedor: null,
      dataEntrada: false,
      dataCompra: false,
      dataEntradaEmEstoque: null,
      dataCompraMercadoria: null,
      codigosNaSacola: null,
      produtos: [],
      produtoModal: null,
      snackbar: false,
      timeout: 30000,
      text: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: " #",
        precision: 2,
        masked: false
      },
      headers: [
        { text: "Nome", align: "left", sortable: false },
        { text: "Referência", align: "left", sortable: false },
        { text: "Quantidade", align: "left", sortable: false },
        { text: "Preço de compra", align: "left", sortable: false }
      ],
      headersSacola: [{ text: "Código de barras", align: "left", sortable: false, value: "item" }]
    }
  },
  directives: { money: VMoney },
  created() {
    serverBus.$on("produtosSelecionados", value => {
      this.produtos = value
    })

    serverBus.$on("fornecedorSelected", value => {
      this.idFornecedor = value
    })
  },
  methods: {
    submit() {
      this.abrirProgressCircular()
      if (this.$refs.form.validate() && this.produtos.length > 0) {
        var entrada = new Entrada()
        entrada.IdFornecedor = this.idFornecedor
        entrada.DataCompra = this.dataCompraMercadoria
        entrada.DataEntrada = this.dataEntradaEmEstoque
        entrada.Produtos = []
        this.produtos.forEach(element => {
          var produto = new ProdutosEntrada()
          if (element.codigoBarras != null) {
            var codigosBarra = element.CodigoBarras.split("\n")
            produto.CodigoDeBarras = codigosBarra
          }
          
          var idsSacola = element.idSacola === undefined ? null : element.idSacola.split(",")

          produto.IdProduto = element.idProduto
          produto.Quantidade = parseInt(element.quantidade)
          produto.PrecoCompra = element.precoCompra
          produto.idSacola = idsSacola

          entrada.Produtos.push(produto)
        })

        servicoNotaEntrada.salvar(entrada).then(
          res => {
            if (res.status === 200) {
              if (res.data.length > 0 && res.data[0].status == 412) {
                this.fecharProgressCircular()
                this.snackbar = true
                this.text = res.data[0].detail
              } else {
                this.fecharProgressCircular()
                this.$router.push({ name: "entrada" })
              }
            }
          },
          err => {
            this.fecharProgressCircular()
      // eslint-disable-next-line
            console.log(err)
          }
        )
      } else if (this.produtos.length == 0) {
        this.fecharProgressCircular()
        alert("É obrigatório informar ao menos um produto.")
      }
    },
    visualizarSacola(produto) {
      if (produto != null && produto.idSacola != null && produto.idSacola != "") {
        this.produtoModal = null
        var idsSacola = produto.idSacola.split(",")

        servicoSacola.getSacolaByIdProdutosSemEntrada(idsSacola).then(res => {
          if (res.status === 200) {
            if (res.data == null) {
              this.snackbar = true
              this.text = "Sacola não encontrada. Verifique."
            } else if (res.data.length > 0 && res.data[0].status == 412) {
              this.snackbar = true
              this.text = res.data[0].detail
            } else {
              produto.quantidade = res.data.produtos.length
              this.codigosNaSacola = res.data.produtos
            }
          }
        })

        this.dialog = true
      }
    },
    validarCodigoBarra(produtoRomaneio) {
      this.abrirProgressCircular()
      let codigosBarra = produtoRomaneio.CodigoBarras.split("\n")
      if (produtoRomaneio.quantidade == codigosBarra.length) {
        servicoCodigoBarras.validarCodigoEmOutraEntrada(codigosBarra).then(res => {
          if (res.data.length > 0) {
            this.fecharProgressCircular()
            alert(res.data)
          } else {
            this.fecharProgressCircular()
            this.dialog = false
          }
        })
      } else {
        this.fecharProgressCircular()
        alert(
          "Quantidade informada " +
            produtoRomaneio.quantidade +
            " foram informados " +
            codigosBarra.length +
            " códigos de barras"
        )
      }
    },
    abrirModal(produto) {
      this.produtoModal = produto
      this.dialog = true
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
