import axios from "axios"

export class ServicoCodigoBarras {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/CodigoBarras` //process.env.VUE_APP_URL_TRIG_GESTOR
  }
  buscarCodigoBarras() {
    return axios({
      url: this.urlServico + `/GetAll`,
      method: "GET"
    })
  }
  salvarCodigoBarras(codigoBarras) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/Salvar", codigoBarras, config).catch(error => {
      alert("Erro ao gerar código de barras " + error)
      return Promise.reject(error)
    })
  }
  imprimir(id) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios({
      url: this.urlServico + `/CodigoBarrasPDF?idCodBarrasInformacoes=${id}`,
      method: "GET",
      responseType: "blob",
      ...config // important
    })
  }
  getCodigosInvalidos(codigosBarras, idProduto) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }

    return axios
      .post(
        this.urlServico + "/ValidarCodigoDeBarrasDisponiveis",
        { CodigoBarra: codigosBarras, IdProduto: idProduto },
        config
      )
      .catch(error => {
        alert("Erro ao validar codigo de barras " + error)
        return Promise.reject(error)
      })
  }
  validarCodigoEmOutraEntrada(codigosBarras) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios
      .post(this.urlServico + "/ValidarCodigoEmOutraEntrada", { CodigoBarra: codigosBarras }, config)
      .catch(error => {
        alert("Erro ao validar codigo de barras " + error)
        return Promise.reject(error)
      })
  }
}
