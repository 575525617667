import axios from "axios"

export class ServicoProduto {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Produto` //process.env.VUE_APP_URL_TRIG_GESTOR
  }
  buscarProdutosTable() {
    return axios({
      url: this.urlServico + `/GetAllPaginaPrincipal`,
      method: "GET"
    })
  }
  buscarProdutosSelect() {
    return axios({
      url: this.urlServico + `/GetProdutosParaSelect`,
      method: "GET"
    })
  }
  buscarProdutosRomaneioSelect(idRomaneio) {
    return axios({
      url: this.urlServico + `/GetProdutosParaSelectByIdRomaneio?idRomaneio=${idRomaneio}`,
      method: "GET"
    })
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `/GetById?id=${id}`,
      method: "GET"
    })
  }
  buscarViewById(id, dataInicioCobrança, dataFimCobrança) {
    // return axios({
    //   url: this.urlServico + `/GetViewById?id=${id}`,
    //   method: "GET"
    // })
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios
      .post(this.urlServico + "/GetViewById", { dataInicioCobrança, dataFimCobrança, id }, config)
      .catch(error => {
        alert("Erro ao carregar produto " + error)
        return Promise.reject(error)
      })
  }
  buscarVendasProPracaPorId(id) {
    return axios({
      url: this.urlServico + `/GetVendasProPracaPorId?id=${id}`,
      method: "GET"
    })
  }
  referenciaDisponivel(referencia) {
    return axios({
      url: this.urlServico + `/ReferenciaNaoExiste?referencia=${referencia}`,
      method: "GET"
    })
  }
  salvarProduto(produto) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/Salvar", produto, config).catch(error => {
      alert("Erro ao salvar um novo produto " + error)
      return Promise.reject(error)
    })
  }
}
